<!-- REFACTOR: Implement this component everywhere.  -->
<template>
  <FormKit v-model="address" type="group" :name="formkitGroupName">
    <template v-if="withName">
      <div class="row">
        <FormKit
          v-model="entityTypeValue"
          type="select"
          name="type"
          :options="getCustomerTypes()"
        />
      </div>
      <FirstLastName v-if="entityTypeValue === 'private'" />
      <!-- REFACTOR: Extract companyName/careOf and other occurences to seperate component. -->
      <div v-else class="row">
        <div class="col-sm-6">
          <FormKit name="companyName" label="Firma*" validation="required" />
        </div>
        <div class="col-sm-6">
          <FormKit name="careOf" label="Ansprechpartner:in / Abteilung" />
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-sm-6">
        <FormKit name="street" label="Straße*" validation="required" />
      </div>
      <div class="col-sm-6">
        <FormKit
          name="houseNumber"
          label="Hausnummer*"
          validation="required"
          help='"10" oder "10b"'
        />
      </div>
    </div>
    <ZipAndTheCityInput
      :prefilled-zip-code="prefilledZipCode"
      :zip-input-disabled="zipInputDisabled"
      :is-custom-city-input-enabled="isCustomCityInputEnabled"
    />
    <FormKit type="hidden" name="country" value="DE" />
  </FormKit>
</template>

<script setup lang="ts">
import ZipAndTheCityInput from "~/components/02_molecules/customer-area/ZipAndTheCityInput.vue";
import type { Address, EntityType } from "~/src/generated-sources/public";
import FirstLastName from "~/components/02_molecules/forms/FirstLastName.vue";
import getCustomerTypes from "~/utils/customer-area/getCustomerTypes";

const {
  withName = true,
  formkitGroupName = "address",
  prefillAddress,
  defaultEntityType,
} = defineProps<{
  prefilledZipCode?: string;
  zipInputDisabled?: boolean;
  isCustomCityInputEnabled?: boolean;
  /** If defined, the form will be prefilled with the given address. */
  prefillAddress?: Address;
  defaultEntityType?: EntityType;
  formkitGroupName?: string;
  withName?: boolean;
}>();

const address = ref({ ...prefillAddress });

const entityTypeValue = ref<EntityType>(
  defaultEntityType || address.value.type || "private",
);
</script>
